import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import CardsDownload from '../modules/Cards/CardsDownload'; // Ruta corregida
import Footer from '../components/Footer';


const AyudaVentas = () => {
  return (
    <>
    <Container>
      <Row>
      <Col md={4}>
        <CardsDownload
            title="Planes 2025 Excel"
            bodyText="Valores en planes generales"
            buttonText="Descargar"
            margin="20px"
            imageSrc="/assets/img/ayudaventas/excel.png"
            link="../assets/doc/ayudaVentas/TARIFARIO 2024-2025.xlsx"
          />
        </Col>
      <Col md={4}>
          <CardsDownload
            title="Precios 2025"
            bodyText="Planes Hogar"
            buttonText="Ver Ahora"
            margin="20px"
            imageSrc="/assets/img/ayudaventas/precios.png"
            link="../assets/doc/ayudaVentas/TARIFARIO 2024-2025.pdf"
          />
        </Col>
        <Col md={4}>
          <CardsDownload
            title="PYMES 2025"
            bodyText="Tarifario de empresas"            
            buttonText="Ver Ahora"
            margin="20px"
            imageSrc="/assets/img/ayudaventas/pymes.png"
            link="../assets/doc/ayudaVentas/TARIFARIO PYMES 2024-2025.pdf"
          />
        </Col>
        <Col md={4}>
          <CardsDownload
            title="4x6 2025"
            bodyText="Valor de la oferta, precio mensual y total"
            buttonText="Ver Ahora"
            margin="20px"
            imageSrc="/assets/img/ayudaventas/valor.png"
            link="../assets/doc/ayudaVentas/TARIFARIO 4X6 2024-2025.pdf"
          />
        </Col>
      </Row>
     </Container>
     <Footer />
    </>
  );
};

export default AyudaVentas;
