import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

function MainMenu() {
  return (
    <Navbar className="navbar-morado" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logos/logo-velonet-blanco.png"}
            width="100"
            className="d-inline-block align-top"
            alt="Logo Blanco Velonet"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-morado" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="directory" style={{ color: "white", margin: "10px" }}>Directorio</Nav.Link>
            <NavDropdown title="Talento Humano" id="basic-nav-dropdown" className="dropdown-white" style={{ color: "white", margin: "10px" }}>
              <NavDropdown.Item as={Link} to="talentohumano">Portal Talento Humano</NavDropdown.Item>
              <NavDropdown.Item href={process.env.PUBLIC_URL + "/assets/doc/talentoHumano/formatos/22. Solicitud de Vacaciones.pdf"} target="_blank">Formato Vacaciones</NavDropdown.Item>
              <NavDropdown.Item href={process.env.PUBLIC_URL + "/assets/doc/talentoHumano/formatos/26. Formato_Solicitud_Permisos_Cortos.pdf"} target="_blank">Formato Permisos Cortos</NavDropdown.Item>
              <NavDropdown.Item href={process.env.PUBLIC_URL + "/assets/doc/talentoHumano/formatos/formato-dia-de-la-familia.pdf"} target="_blank">Formato Día de la Familia</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="culturavelonet" style={{ backgroundColor: "#592582", color: "#FFF" }}>Cultura Velonet</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="buzonsugerencias" style={{ color: "white", margin: "10px" }}>Buzón de sugerencias</Nav.Link>
            <Nav.Link href="/ayudaventas" style={{ color: "white", margin: "10px" }}>Ayuda Ventas</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainMenu;
