import './App.css';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import MainMenu from './modules/MainMenu/MainMenu';
import Cards from './modules/Cards/Cards';
import Banners from './modules/Banners/Banners';
import AccordionModule from './components/AccordionModule';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Directory from './pages/Directory';
import TalentoHumano from './pages/TalentoHumano';
import BuzonSugerencias from './pages/BuzonSugerencias';
import Convenios from './pages/Convenios';
import Lovelonet from './pages/Lovelonet';
import Login from './pages/Login';
import Footer from './components/Footer';
import CulturaVelonet from './pages/CulturaVelonet';
import VeloCx from './pages/VeloCx';
import ReservarSala from './pages/ReservarSala';
import AyudaVentas from './pages/AyudaVentas';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  if (!loggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="App">
      <Router>
        <MainMenu />
        <Routes>
          {/* Define las rutas */}
          <Route path="/" element={<Home />} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/talentohumano" element={<TalentoHumano />} />
          <Route path="/buzonsugerencias" element={<BuzonSugerencias />} />
          <Route path="/convenios" element={<Convenios />} />
          <Route path="/lovelonet" element={<Lovelonet />} />          
          <Route path="/culturavelonet" element={<CulturaVelonet />} />          
          <Route path="/velocx" element={<VeloCx />} />
          <Route path="/reservasala" element={<ReservarSala />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ayudaventas" element={<AyudaVentas />}/>
        </Routes>
      </Router>
    </div>
  );
};

function Home() {
  return (
    <>
      <Banners />
      <Container>
        <br />
        <h1 style={{ color: "#592582" }}>Nuestro Propósito Mayor</h1>
        <h5>Contribuir a la comunicación, a través de la interconectividad, promoviendo la creatividad, la innovación y progreso social de los seres humanos.</h5>
      </Container>
      <AccordionModule />
      <br />
      <Container>
        <Row>
          <Col md={4}>
            <Cards
              title="Directorio"
              bodyText="Correos corporativos, números e información adicional."
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/icono-directorio.png"
              link="/directory" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Talento Humano"
              bodyText="Formatos de vacaciones, permisos y mucho más"
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/talento.png"
              link="/talentohumano" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Buzón de Sugerencias"
              bodyText="Deja tu sugerencia de forma anónima"
              buttonText="Dejar sugerencia"
              margin="20px"
              imageSrc="/assets/img/buzon.png"
              link="/buzonsugerencias" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Convenios"
              bodyText="Disfruta de estos beneficios empresariales"
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/shoppingbag.png"
              link="/convenios" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Ayuda Ventas"
              bodyText="Encuentra los valores de los planes Velonet"
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/ayudaventas/ayudaventas.png"
              link="/ayudaventas" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Razones para amar a Velonet"
              bodyText="Conoce los beneficios de pertenecer a esta gran familia"
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/icono-lovelonet.png"              
              link="/lovelonet" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="VeloCX"
              bodyText="Boletín de Experiencia del Cliente con un resumen mensual"
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/velocx.png"              
              link="/velocx" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Cultura Velonet"
              bodyText="Los valores que nos mueven, conócelos, compártelos, aplícalos"
              buttonText="Ver más"
              margin="20px"
              imageSrc="/assets/img/cultura.png"              
              link="/culturavelonet" // Esta será la URL a la que se dirigirá el botón
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Capacitaciones"
              bodyText="Adquiere conocimientos y mejora tus habilidades"
              buttonText="Próximamente"
              margin="20px"
              imageSrc="/assets/img/capacitaciones.png"
            />
          </Col>
          <Col md={4}>
            <Cards
              title="Entérate Velonet"
              bodyText="Conoce los últimos avances de Velonet"
              buttonText="Próximanente"
              margin="20px"
              imageSrc="/assets/img/noticias.png"
            />
          </Col>
        </Row>
        </Container>
        <Footer />
    </>
  );
}

export default App;
